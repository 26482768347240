import {Dictionary, Locales} from '@core/locales';
import _ from 'underscore';

import {CommonLocalesKeys} from './CommonLocalesKeys';
import Access from './strings/AccessTab/Access.json';
import AccessConfirmationPrompt from './strings/AccessTab/ConfirmationPrompt/AccessConfirmationPrompt.json';
import CouldNotUpdateAccessPrompt from './strings/AccessTab/CouldNotUpdateAccessPrompt/CouldNotUpdateAccessPrompt.json';
import AccessTooltips from './strings/AccessTab/Tooltip/AccessTooltips.json';
import CaseClassificationModel from './strings/CaseAssist/CaseClassificationModel.json';
import ComparisonDatePicker from './strings/ComparisonDatePicker/ComparisonDatePicker.json';
import ContentBrowser from './strings/ContentBrowser.json';
import FeatureFlags from './strings/FeatureFlags.json';
import Filters from './strings/Filters/Filters.json';
import GlobalContent from './strings/GlobalContent.json';
import Language from './strings/Language.json';
import status from './strings/Model/status.json';
import Operations from './strings/Operations.json';
import PlatformErrorCodes from './strings/PlatformErrorCodes.json';
import Privileges from './strings/Privileges.json';
import ResourceTypes from './strings/ResourceTypes.json';
import Schedules from './strings/Schedules.json';
import SecurityProvider from './strings/SecurityProvider.json';
import SmartSnippetsModel from './strings/SmartSnippets/SmartSnippetsModel.json';

import SourceErrorCodes from './strings/SourceErrorCodes.json';
import Strings from './strings/Strings.json';
import Blanslate from './strings/Table/Blanslate.json';
import defaults from './strings/Table/defaults.json';
import Validation from './strings/validation.json';

const req: Record<string, Record<string, any>> = {
    'strings/SourceErrorCodes': SourceErrorCodes,
    'strings/Filters/Filters': Filters,
    'strings/ContentBrowser': ContentBrowser,
    'strings/Language': Language,
    'strings/SecurityProvider': SecurityProvider,
    'strings/PlatformErrorCodes': PlatformErrorCodes,
    'strings/Privileges': Privileges,
    'strings/AccessTab/Tooltip/AccessTooltips': AccessTooltips,
    'strings/AccessTab/ConfirmationPrompt/AccessConfirmationPrompt': AccessConfirmationPrompt,
    'strings/AccessTab/CouldNotUpdateAccessPrompt/CouldNotUpdateAccessPrompt': CouldNotUpdateAccessPrompt,
    'strings/AccessTab/Access': Access,
    'strings/CaseAssist/CaseClassificationModel': CaseClassificationModel,
    'strings/SmartSnippets/SmartSnippetsModel': SmartSnippetsModel,
    'strings/Schedules': Schedules,
    'strings/ResourceTypes': ResourceTypes,
    'strings/Table/Blanslate': Blanslate,
    'strings/Table/defaults': defaults,
    'strings/FeatureFlags': FeatureFlags,
    'strings/Model/status': status,
    'strings/Strings': Strings,
    'strings/GlobalContent': GlobalContent,
    'strings/Operations': Operations,
    'strings/Validation': Validation,
    'strings/ComparisonDatePicker/ComparisonDatePicker': ComparisonDatePicker,
};
const translations = {};

Object.keys(req).forEach((key) => {
    const parts: string[] = key.split('/');
    const roots: string[] = parts.splice(1, parts.length - 2);

    let map: any = translations;

    _.each(roots, (root: string) => {
        if (!map[root]) {
            _.extend(map, {[root]: {}});
        }
        map = map[root];
    });

    _.extend(map, req[key]);
});

const enDictionary: Dictionary = {
    locale: 'en',
    translations,
};

export const commonLocales: Locales<CommonLocalesKeys> = new Locales<CommonLocalesKeys>({
    dictionaries: [enDictionary],
    defaultLocale: 'en',
});
